<template>
  <div>
    <!-- pic823592313A3E7E928E44CD947C21D2C9.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/connect-in-lu.jpg">

    <p>
      LEGO Universe will offer worlds of ways for LEGO fans to socialize and connect online!
    </p>
    <p>
      Band together with your LEGO Universe friends and family in an epic battle for a brighter future! Besides forging friendships in the fight to save the Universe, you can gather together for fast-paced player vs. player competitions!
    </p>
    <p>
      LEGO Universe will include a wide range of communication features for maximum fun! SpeedChat with your allies as you advance into the gameworld, or use Mail to send out holiday greetings! LEGO Universe also features FullChat capabilities!
    </p>
    <p>
      Lock your browser right here on the LEGO Universe News Network for more on how friends and family members will safely connect in the first Massively Multiplayer Online Game (MMOG) for LEGO fans!!
    </p>
  </div>
</template>
